// import { fetchReticulumAuthenticated } from "./phoenix-utils";
import defaultMaleAvatar from "../assets/models/DefaultAvatarMale.glb";
import defaultFemaleAvatar from "../assets/models/DefaultAvatarFemale.glb";
import configs from "./configs";

const qs = new URLSearchParams(location.search);
const name = qs.get("name") ?? "User";
const gender = qs.get("gender") ?? "female";
const userAvatarId = qs.get("avatarId") ?? "1234";
const names = [name];
// const avatarMappings = configs.translation("avatar-mappings");
const s3configuredbucket = configs.translation("avatar-s3-bucket");
// const s3Bucket = "onthemoons";
const s3Bucket = s3configuredbucket;

const avatarUrl = "https://" + s3Bucket + ".s3.amazonaws.com/avatars/glb/" + userAvatarId + ".glb";

function chooseRandom(arr) {
  return arr[Math.floor(Math.random() * arr.length)];
}

export function generateRandomName() {
  return `${chooseRandom(names)}-${Math.floor(10000 + Math.random() * 10000)}`;
}

export async function fetchRandomDefaultAvatarId() {
  console.log(s3configuredbucket);
  if (userAvatarId === "1234" || !userAvatarId) {
    //   If reticulum doesn't return any default avatars, just default to the duck model. This should only happen
    //   when running against a fresh reticulum server, e.g. a local ret instance.
    return gender == "male"
      ? new URL(defaultMaleAvatar, location.href).href
      : new URL(defaultFemaleAvatar, location.href).href;
  } else {
    return avatarUrl;
  }
}
